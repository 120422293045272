<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          เมนู <v-spacer></v-spacer> <v-btn
            color="primary"
            @click="newmenu"
          >
            เพิ่มเมนู
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="auto">
            <v-btn
              icon
              color="info"
              @click="refreshmenu"
            >
              <v-icon>{{ icons.mdiReload }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              label="ค้นหา เมนู"
              dense
              :prepend-icon="icons.mdiMagnify"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              color="info"
              @click="groupdialogm = true"
            >
              จัดการหมวดหมู่
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-tabs
              v-model="tab"
              align-with-title
            >
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab
                v-for="menugroup in menugroups"
                :key="menugroup._id"
              >
                {{ menugroup.name }}
              </v-tab>
            </v-tabs>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="menus"
              :items-per-page="25"
              class="elevation-1"
              :loading="tableloader"
            >
              <template v-slot:body="props">
                <draggable
                  :list="props.items"
                  tag="tbody"
                  @update="reordermenu(props.items)"
                >
                  <tr
                    v-for="(menuitem, index) in props.items"
                    :key="index"
                  >
                    <td>
                      <v-icon
                        small
                        class="page__grab-icon"
                      >
                        {{ icons.mdiArrowSplitHorizontal }}
                      </v-icon>
                    </td>
                    <td> {{ index + 1 }} </td>
                    <td> {{ menuitem.name }} </td>
                    <td> {{ getgrouptext(menuitem.menugroup_id) }} </td>
                    <td> {{ menuitem.description }} </td>
                    <td> {{ menuitem.price }} </td>
                    <td :style="{ display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                      <v-btn
                        icon
                        color="info"
                        @click="editmenu(menuitem)"
                      >
                        <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="error"
                        @click="deletemenu(menuitem)"
                      >
                        <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :retain-focus="false"
        persistent
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">เมนู</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="currentmenu">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentmenu.name"
                    label="ชื่อ"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="currentmenu.menugroup_id"
                    label="หมวดหมู่"
                    :items="menugroups"
                    item-text="name"
                    item-value="_id"
                    dense
                    hide-details
                    outlined
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentmenu.description"
                    label="คำอธิบาย"
                    dense
                    hide-details
                    outlined
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentmenu.price"
                    label="ราคา"
                    dense
                    hide-details
                    outlined
                    type="text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-img
                    v-if="currentmenu.image"
                    width="300"
                    :src="'https://nextbooking.ap-south-1.linodeobjects.com/' + currentmenu.image"
                  ></v-img>
                </v-col>
                <v-col
                  cols="8"
                >
                  <v-file-input
                    v-model="imgtmp"
                    :prepend-icon="icons.mdiCamera"
                    label="รูปภาพ"
                    dense
                    hide-details
                    outlined
                  ></v-file-input>
                </v-col>
                <v-col
                  v-if="dialogmode === 'edit'"
                  cols="2"
                >
                  <v-btn
                    color="error"
                    @click="removeimg(currentmenu)"
                  >
                    ลบรูปภาพ
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="savemenu"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-dialog
      v-model="groupdialogm"
      width="700"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">จัดการหมวดหมู่</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="currentgroup.name"
                label="ชื่อ"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="currentgroup.description"
                label="คำอธิบาย"
                dense
                hide-details
                outlined
                type="text"
              ></v-text-field>
            </v-col>
            <v-col
              cols="4"
            >
              <v-btn
                :color="groupmode === 'new' ? 'primary': 'info'"
                @click="savemenugroup"
                v-text="groupmode === 'new' ? 'สร้างหมวดหมู่': 'บันทึกหมวดหมู่'"
              >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :search="search"
            :headers="groupheaders"
            :items="menugroups"
            :items-per-page="25"
            class="elevation-1"
          >
            <template v-slot:body="props">
              <draggable
                :list="props.items"
                tag="tbody"
                @update="reordermenugroup(props.items)"
              >
                <tr
                  v-for="(menugroup, index) in props.items"
                  :key="index"
                >
                  <td>
                    <v-icon
                      small
                      class="page__grab-icon"
                    >
                      {{ icons.mdiArrowSplitHorizontal }}
                    </v-icon>
                  </td>
                  <td> {{ index + 1 }} </td>
                  <td> {{ menugroup.name }} </td>
                  <td> {{ menugroup.description }} </td>
                  <td :style="{ display: 'flex', justifyContent: 'center' }">
                    <v-btn
                      icon
                      color="info"
                      @click="editmenugroup(menugroup)"
                    >
                      <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="error"
                      @click="deletemenugroups(menugroup)"
                    >
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
  mdiReload,
  mdiArrowSplitHorizontal,
} from '@mdi/js'
import moment from 'moment'
import draggable from 'vuedraggable'

export default {
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiReload,
        mdiArrowSplitHorizontal,
      },
    }
  },
  components: {
    draggable,
  },
  data() {
    return {
      tab: 0,
      search: '',
      groupdialogm: false,
      dialog: false,
      imgdialog: false,
      imgtmp: null,
      statusitems: [
        { label: 'รอสลิป', value: 'waiting' },
        { label: 'ส่งสลิปแล้ว', value: 'waitingc' },
        { label: 'ยืนยันการจอง', value: 'confirm' },
        { label: 'ยกเลิก', value: 'cancel' },
      ],
      headers: [
        {
          text: 'ย้าย', align: 'start', value: 'no', width: '20px', sortable: false,
        },
        {
          text: '#', align: 'start', value: 'no', width: '20px', sortable: false,
        },
        { text: 'ชื่อ', align: 'start', value: 'name' },
        { text: 'หมวดหมู่', align: 'start', value: 'menugroup_id' },
        { text: 'คำอธิบาย', value: 'description' },
        { text: 'ราคา', value: 'price' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      groupheaders: [
        {
          text: 'ย้าย', align: 'start', value: 'no', width: '20px', sortable: false,
        },
        {
          text: '#', align: 'start', value: 'no', width: '20px', sortable: false,
        },
        { text: 'ชื่อ', align: 'start', value: 'name' },
        { text: 'คำอธิบาย', value: 'description' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      menus: [],
      menugroups: [],
      menu: '',
      dialogmode: 'new',
      groupmode: 'new',
      tableloader: false,
      currentmenu: {
      },
      currentgroup: {
      },
      menuquery: {

      },
    }
  },
  watch: {
    tab() {
      this.menuquery.menugroup_id = this.menugroups[this.tab]._id
      this.getmenus()
    },
  },
  async created() {
    await this.getmenugroups()
    this.getmenus()
  },
  methods: {
    async reordermenu(items) {
      const newitemorders = items.map((item, index) => ({ _id: item._id, no: index }))
      await this.axios.put('/admin/menu/reorder', {
        newitemorders,
      })
    },
    editmenu(item) {
      this.dialogmode = 'edit'
      this.currentmenu = item
      this.dialog = true
    },
    editmenugroup(item) {
      this.groupmode = 'edit'
      this.currentgroup = item
    },
    newmenu() {
      this.dialogmode = 'new'
      this.dialog = true
      this.currentmenu = {
      }
    },
    async removeimg(item) {
      if (confirm('ยืนยันการลบ ?')) {
        await this.axios.delete('/admin/menu/image', { params: { _id: item._id } })
        this.getmenus()
        this.dialogmode = 'new'
        this.dialog = false
        this.currentmenu = {
        }
      }
    },
    async getmenus() {
      this.tableloader = true
      this.menuquery.menugroup_id = this.menugroups[this.tab]._id
      let { data: { menus } } = await this.axios.get('/admin/menus', { params: this.menuquery })
      menus = menus.map(menu => ({
        ...menu,
        action: '',
        datetime: moment(menu.datetime).format('YYYY-MM-DD HH:mm'),
        updatedAt: moment(menu.updatedAt).format('YYYY-MM-DD HH:mm'),
        createdAt: moment(menu.createdAt).format('YYYY-MM-DD HH:mm'),
      }))
      this.menus = menus
      this.tableloader = false
    },
    getstatustext(statusvalue) {
      const status = this.statusitems.find(item => item.value === statusvalue)

      return status.label
    },
    getgrouptext(menugroupId) {
      const group = this.menugroups.find(item => item._id === menugroupId)

      return group.name
    },
    incoming() {
      this.menuquery = {
        datetime: new Date(),
      }
      this.getmenus()
    },
    refreshmenu() {
      this.menuquery = {}
      this.getmenus()
    },
    async deletemenu(item) {
      if (confirm('Are you sure you want to delete this menu')) {
        await this.axios.delete('/admin/menu', { params: { _id: item._id } })
        await this.getmenus()
        this.reordermenu(this.menus)
      }
    },
    async reordermenugroup(items) {
      const newitemorders = items.map((item, index) => ({ _id: item._id, no: index }))
      await this.axios.put('/admin/menugroup/reorder', {
        newitemorders,
      })
    },
    async deletemenugroups(item) {
      if (confirm('การลบหมวดหมู่จะทำให้เมนูที่อยู่ในหมวดหมู่หายไป')) {
        await this.axios.delete('/admin/menugroup', { params: { _id: item._id } })
        await this.getmenugroups()
        this.reordermenugroup(this.menugroups)
        await this.getmenus()
      }
    },
    async getmenugroups() {
      const { data: { menugroups } } = await this.axios.get('/admin/menugroups')
      this.menugroups = menugroups
    },
    async savemenugroup() {
      try {
        if (this.groupmode === 'edit') {
          await this.axios.put('/admin/menugroup', {
            name: this.currentgroup.name,
            description: this.currentgroup.description,
          }, { params: { _id: this.currentgroup._id } })
        } else {
          await this.axios.post('/admin/menugroup', {
            name: this.currentgroup.name,
            description: this.currentgroup.description,
          })
        }
        this.currentgroup = {}
        await this.getmenugroups()
        await this.getmenus()
      } catch (error) {
        if (error.response.status === 401) {
          this.$cookies.set('t', '')
          this.$router.push({ name: 'Login' })
        }
      }
      this.groupmode = 'new'
    },
    async savemenu() {
      if (this.$refs.currentmenu.validate()) {
        const currenntmenu = new FormData()
        currenntmenu.append('name', this.currentmenu.name)
        currenntmenu.append('description', this.currentmenu.description)
        currenntmenu.append('menugroup_id', this.currentmenu.menugroup_id)
        currenntmenu.append('price', this.currentmenu.price)
        if (this.imgtmp) {
          currenntmenu.append('img', this.imgtmp)
        }
        try {
          if (this.dialogmode === 'edit') {
            await this.axios.put('/admin/menu', currenntmenu, { params: { _id: this.currentmenu._id } })
          } else {
            await this.axios.post('/admin/menu', currenntmenu)
          }
          await this.getmenus()
        } catch (error) {
          if (error.response.status === 401) {
            this.$cookies.set('t', '')
            this.$router.push({ name: 'Login' })
          }
        }
        this.sliptmp = null
        this.dialog = false
      }
    },
  },
}
</script>
